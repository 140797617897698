import { useState, useEffect } from 'react';
import DesktopHeader from './header-components/DesktopHeader.jsx';
import MobileHeader from './header-components/MobileHeader.jsx';
import HomeLanding from '../components/HomeLanding'
import OurServices from '../components/OurServices'
import { useDispatch, useSelector } from 'react-redux';
import OurTeam from '../components/OurTeam'
import { addText, getHeaderTexts, getHomeTexts, getMiscTexts, getTextByCategory, reset } from '../features/texts/textSlice';
import Spinner from '../components/Spinner';
import AdminHeader from './header-components/AdminHeader.jsx';
import { toast } from 'react-toastify';

function Header({setShowBookNow}) {
    const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1100);
    const [ourServicesOpen, setOurServicesOpen] = useState(false);
  
    // Resize Event Listener
    useEffect(() => {
      const handleResize = () => {
        setSmallScreen(window.innerWidth < 1100);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      }, []);

      const dispatch = useDispatch();

      const { header_texts, home_texts, misc_texts, isLoading, isError, message } = useSelector(
          (state) => state.texts
      )

      useEffect(() => {
          if (isError) {
              toast.error(message);
          }
          return () => {
              dispatch(reset());
          };
      }, [header_texts, home_texts, misc_texts, isError, message, dispatch]);

    useEffect(() => {
        dispatch(getHeaderTexts());
        dispatch(getHomeTexts());
        dispatch(getMiscTexts());
    }, []);

    if(isLoading || !header_texts){
      return <Spinner />
    }

    // Check if the current URL path starts with "/admin"
    if (window.location.pathname.startsWith("/admin-free-tours")) {
      return null
    }

    // Check if the current URL path starts with "/admin"
    if (window.location.pathname.startsWith("/admin")) {
      return <div className='header'><AdminHeader texts={header_texts} home_texts = {home_texts} setShowBookNow = {setShowBookNow} ourServicesOpen={ourServicesOpen} setOurServicesOpen={setOurServicesOpen}/></div>
    }

    // Check if the current URL path starts with "/admin"
    if (window.location.pathname.startsWith("/virtual")) {
      return (
        <div className="header">
            {ourServicesOpen ? <div className="dropdown-background"></div> : null}
              {smallScreen ? 
                <MobileHeader virtual={true} texts={header_texts} misc_texts={misc_texts} setShowBookNow = {setShowBookNow} ourServicesOpen={ourServicesOpen} setOurServicesOpen={setOurServicesOpen}/>
              : 
                <DesktopHeader virtual={true} texts={header_texts} misc_texts = {misc_texts} setShowBookNow = {setShowBookNow} ourServicesOpen={ourServicesOpen} setOurServicesOpen={setOurServicesOpen}/>
              }
        </div>
      )
    }

    return (
        <div className="header">
            {ourServicesOpen ? <div className="dropdown-background"></div> : null}
              {smallScreen ? 
                <MobileHeader texts={header_texts} misc_texts={misc_texts} setShowBookNow = {setShowBookNow} ourServicesOpen={ourServicesOpen} setOurServicesOpen={setOurServicesOpen}/>
              : 
                <DesktopHeader texts={header_texts} misc_texts = {misc_texts} setShowBookNow = {setShowBookNow} ourServicesOpen={ourServicesOpen} setOurServicesOpen={setOurServicesOpen}/>
              }
        </div>
    )
}

export default Header