import ButtonPrimary from '../style-components/ButtonPrimary';
import gps_game from '../../img/gps-game.png';

function FooterColumnMiddle({ texts }) {
  const contactUsText = texts && texts["contact-us:"] ? texts["contact-us:"].text : '';
  const taxAddressText = texts && texts["tax-address"] ? texts["tax-address"].text : '';

  return (
    <div className="footer-column middle">
      <h3 className="cardo contact-us-footer">{contactUsText}</h3>
      <p className='padding-20-top line-height-spaced'>
        <a className="dark" href="mailto:info@talesofreval.ee">info@talesofreval.ee</a>
        <br />
        <a className="dark " href="tel:+372 5555 5555">+372 5560 4421</a>
      </p>
      <p className="padding-20-top">
        OÜ Satsang <br />
        Reg no. 14443936 <br />
        {taxAddressText}
      </p>
    </div>
  );
}

export default FooterColumnMiddle;
