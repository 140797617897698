import OurTeamCard from './style-components/OurTeamCard';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getStorytellers, reset } from '../features/storytellers/storytellerSlice';
import { toast } from 'react-toastify';
import PaymentCard from './PaymentCard';

function OurTeamList() {
  const dispatch = useDispatch();
  const { storytellers, isLoading, isError, message } = useSelector((state) => state.storytellers);

  const [paymentOpen, setPaymentOpen] = useState(false);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    dispatch(getStorytellers());

    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch]);

  const startPayment = (links) => {
    setLinks(links);
    setPaymentOpen(true);
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="our-team-grid">
      {storytellers.map((storyteller) => (
        <OurTeamCard
          key={storyteller._id}
          bgimage={storyteller.image.src} // Assuming image.src contains the URL
          title={storyteller.name}
          email={storyteller.email}
          phone={storyteller.phone}
          links={storyteller.payment_links}
          startPayment={startPayment}
        />
      ))}

      {paymentOpen && <PaymentCard links={links} closePaymentCard={() => setPaymentOpen(false)}/>}
      
    </div>
  );
}

export default OurTeamList;