import axios from 'axios'

const API_URL = '/storytellers/'


// Get storytellers
const getStorytellers = async () => {
    const response = await axios.get(API_URL)
    return response.data
}

// Add storyteller
const addStoryteller = async (token, storyteller) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL, storyteller, config)
  return response.data
}

const storytellerService = {
  getStorytellers,
  addStoryteller,
}

export default storytellerService