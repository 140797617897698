import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaAngleLeft, FaAngleRight, FaPlus, FaTimes } from "react-icons/fa";
import { addDate, getDates, removeDate, reset } from "../features/tour/tourSlice";
import { toast } from "react-toastify";

function AdminFreeTours() {
  const dispatch = useDispatch();

  const { dates, isError, isLoading, message } = useSelector(
    (state) => state.tour
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    // if (dates.length > 0) {
    //   console.log(dates);
    // }

    return () => {
      dispatch(reset());
    };
  }, [dates, isError, isLoading, message, dispatch]);

  useEffect(() => {
    dispatch(getDates());
  }, [dispatch]);

  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(null);
  const [calendar, setCalendar] = useState([]);

  const [creatingDates, setCreatingDates] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);

  const [selectedTimes, setSelectedTimes] = useState([]);

  const [creatingInSelected, setCreatingInSelected] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);

  const [deleteMessage, setDeleteMessage] = useState('');

  const getDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);
    const days = [];

    // Get the day of the week for the first day of the month (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const firstDayOfWeek = date.getDay();

    // Calculate how many days to add from the previous month to start the calendar on Monday
    const daysFromPrevMonth = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

    // Add days from the previous month
    for (let i = daysFromPrevMonth; i > 0; i--) {
      const prevMonthDate = new Date(year, month, 1 - i);
      days.push(formatDate(prevMonthDate));
    }

    // Add days from the current month
    while (date.getMonth() === month) {
      days.push(formatDate(new Date(date)));
      date.setDate(date.getDate() + 1);
    }

    return days;
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return {
      date: `${year}-${month}-${day}`,
      day: day,
    };
  };

  const getMonthName = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return months[date.getMonth()];
  };

  useEffect(() => {
    setCalendar(
      getDaysInMonth(selectedMonth.getFullYear(), selectedMonth.getMonth())
    );
  }, [selectedMonth]);

  const getDateTours = (date) => {
    return dates.filter((tour) => tour.date === date.date);
  };

  const hasTours = (date) => {
    return dates.some((tour) => tour.date === date.date);
  };

  const triggerCreateDates = () => {
    setSelectedTimes([]);
    setCreatingInSelected(false);
    setSelectedDays([]);
    setSelectedDay(null);
    setCreatingDates(!creatingDates);
    setDeleteMessage('');
    }

    const handleSelectDay = (date) => {
        setDeleteMessage('');
        setSelectedTimes([]);
        setCreatingInSelected(false);
        if(!creatingDates) {
            setSelectedDay(date);
            return;
        }
        if (selectedDays.includes(date)) {
            setSelectedDays(selectedDays.filter((day) => day !== date));
        } else {
            setSelectedDays([...selectedDays, date]);
        }
        getAllTimes([...selectedDays, date]);
    }

    const getAllTimes = (dates_selected) => {
        const dates_with_tours = dates.filter(tour => 
            dates_selected.some(selectedDate => selectedDate.date === tour.date)
        );
    
        const times = [...new Set(dates_with_tours.map(tour => tour.time))];
    
        return times;
    }

    const getDateText = (date) => {
        const dateObject = new Date(date);
        const [year, month, day] = date.split('-');

        return `${day}. ${getMonthName(dateObject)}, ${year}`;
    }

    const handleCheckboxChange = (time) => {
        setSelectedTimes((prevSelectedTimes) => {
            if (prevSelectedTimes.includes(time)) {
                return prevSelectedTimes.filter((t) => t !== time);
            } else {
                return [...prevSelectedTimes, time];
            }
        });
    };

    const handleDelete = () => {
        console.log(deleteMessage)
        if(creatingDates){
            dispatch(removeDate({dates: selectedDays, times: selectedTimes, message: deleteMessage}));
        } else {
            dispatch(removeDate({dates: [selectedDay], times: selectedTimes, message: deleteMessage}));
        }

        setSelectedTimes([]);
        setSelectedDays([]);
        setSelectedDay(null);
        setCreatingDates(false);
        setDeleteMessage('');
    }

    const createTours = () => {
        if(creatingDates){
            dispatch(addDate({dates: selectedDays, time: selectedTime}));
        } else {
            dispatch(addDate({dates: [selectedDay], time: selectedTime}));
        }

        setSelectedDays([]);
        setSelectedDay(null);
        setCreatingDates(false);
        setCreatingInSelected(false);
    }

    useEffect(() => {
        if(selectedTime){
            console.log(selectedTime);
        }
    }, [selectedTime]);

    return (
        <div className="container">
          <div className="admin-free-tours">
            <div className="calendar-actions">
              <div className="month-switch">
                <button
                  onClick={() =>
                    setSelectedMonth(
                      new Date(
                        selectedMonth.getFullYear(),
                        selectedMonth.getMonth() - 1
                      )
                    )
                  }
                >
                  <FaAngleLeft />
                </button>
                <h4>{getMonthName(selectedMonth)}</h4>
                <button
                  onClick={() => {
                    setSelectedMonth(
                      new Date(
                        selectedMonth.getFullYear(),
                        selectedMonth.getMonth() + 1
                      )
                    );
                    setSelectedDay(null);
                    setSelectedDays([]);
                  }}
                >
                  <FaAngleRight />
                </button>
              </div>
              <div className="create-new">
                {creatingDates ? "Cancel" : "Manage dates"}
                <button onClick={() => triggerCreateDates()}>
                  {creatingDates ? <FaTimes /> : <FaPlus />}
                </button>
              </div>
            </div>
    
            <div className="calendar-heading">
              <div className="calendar-heading-day">Mon</div>
              <div className="calendar-heading-day">Tue</div>
              <div className="calendar-heading-day">Wed</div>
              <div className="calendar-heading-day">Thu</div>
              <div className="calendar-heading-day">Fri</div>
              <div className="calendar-heading-day">Sat</div>
              <div className="calendar-heading-day">Sun</div>
            </div>
            <div className="calendar">
              {calendar.map((date, index) => (
                <div
                  className={`
                    calendar-day 
                    ${selectedDay && selectedDay.date === date.date ? "selected" : ""} 
                    ${hasTours(date) ? "has-tour" : ""} 
                    ${creatingDates && selectedDays.includes(date) ? "selected" : ""}
                  `}
                  key={index}
                  onClick={() => handleSelectDay(date)}
                >
                  <p>{date.day}</p>
    
                  <div key={index} className="calendar-date-tours">
                    {getDateTours(date).map((tour, index) => (
                      <div className="calendar-date-tour" key={index}>
                        <span>{tour.time}</span>
                        <span>{tour.bookings}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
    
            {/* SINGLE DAY EDIT */}
            {(!creatingDates && selectedDay) &&
              <div className="selected-day">
                <div>
                  <div className="selected-day-header">
                    <h4>{getDateText(selectedDay.date)}</h4>
                    <button onClick={() => {
                      setCreatingInSelected(!creatingInSelected);
                      setSelectedTimes([]);
                      setDeleteMessage('');
                    }}>
                      {creatingInSelected ? <FaTimes /> : <FaPlus />}
                    </button>
                  </div>
                  <div className="single-date-times">
                    <p className="date-tours padding-10-top">Tours:</p>
                    <ul className="padding-5-top">
                      {getDateTours(selectedDay).map((tour, index) => (
                        <li key={index}>
                          <input 
                            type="checkbox" 
                            checked={selectedTimes.includes(tour.time)}
                            onChange={() => handleCheckboxChange(tour.time)} 
                          />
                          <span>{tour.time} - {tour.bookings}</span>
                        </li>
                      ))}
                    </ul>
    
                    {selectedTimes.length > 0 &&
                      <div className="remove-message">
                        <textarea 
                          placeholder="Delete message" 
                          name="" 
                          id="" 
                          value={deleteMessage} 
                          onChange={(e) => setDeleteMessage(e.target.value)}
                        ></textarea>
                        <button className="delete-tour-time" onClick={() => handleDelete()}>Delete <FaTimes /></button>
                      </div>
                    }
    
                    {creatingInSelected &&
                      <div className="add-new-tour-time">
                        <input type="time" onChange={(e) => setSelectedTime(e.target.value)} />
                        <button onClick={() => createTours()}><FaPlus /></button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
    
            {/* MULTI-DATE EDIT */}
            {(creatingDates) &&
              <div className="selected-day">
                <div>
                  <div className="selected-day-header">
                    <h4>Multi-date editing</h4>
                    <button onClick={() => {
                      setCreatingInSelected(!creatingInSelected);
                      setSelectedTimes([]);
                      setDeleteMessage('');
                    }}>
                      {creatingInSelected ? <FaTimes /> : <FaPlus />}
                    </button>
                  </div>
                  <div className="single-date-times">
                    <p className="date-tours padding-10-top">Selected dates:</p>
                    <ul className="padding-5-top">
                      {selectedDays.map((day, index) => (
                        <li key={index}>
                          <span>{getDateText(day.date)}</span>
                        </li>
                      ))}
                    </ul>
    
                    <p className="date-tours padding-10-top">Available times:</p>
                    <ul className="padding-5-top">
                      {getAllTimes(selectedDays).map((time, index) => (
                        <li key={index}>
                          <input 
                            type="checkbox" 
                            checked={selectedTimes.includes(time)}
                            onChange={() => handleCheckboxChange(time)} 
                          />
                          <span>{time}</span>
                        </li>
                      ))}
                    </ul>
    
                    {selectedTimes.length > 0 &&
                      <div className="remove-message">
                        <textarea 
                          placeholder="Delete message" 
                          name="" 
                          id="" 
                          value={deleteMessage} 
                          onChange={(e) => setDeleteMessage(e.target.value)}
                        ></textarea>
                        <button className="delete-tour-time" onClick={() => handleDelete()}>Delete <FaTimes /></button>
                      </div>
                    }
    
                    {creatingInSelected &&
                      <div className="add-new-tour-time">
                        <input type="time" onChange={(e) => setSelectedTime(e.target.value)} />
                        <button onClick={() => createTours()}><FaPlus /></button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      );
    }

export default AdminFreeTours;