import PaymentButton from '../style-components/PaymentButton'

function OurTeamCard({bgimage, title, email, phone, links, startPayment}) {
  console.log(email + ": ", email === "")
  return (
    <div className="team">
    <div className="team-image">
      <img src={bgimage} alt={title} />
    </div>
    <div className="team-info">
        <h5 className="blue-text padding-20-bottom">{title}</h5>
        {email === "" ? null : <a className = "mail " href={`mailto:${email}`}>{email}</a>}
        {phone === "" ? null : <a className="phone bold padding-20-top padding-20-bottom" href={`tel:${phone}`}>{phone}</a>}
        <PaymentButton text="Tip your guide" onClick={() => startPayment(links)}/>
    </div>
</div>
  )
}

export default OurTeamCard
