import React, {useEffect} from 'react'
import HomeLanding from '../components/HomeLanding'
import OurServices from '../components/OurServices'
import { useDispatch, useSelector } from 'react-redux';
import OurTeam from '../components/OurTeam'
import { getHomeTexts, getMiscTexts, reset } from '../features/texts/textSlice';
import Spinner from '../components/Spinner';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import mobile_virtual_phone from '../img/mobile-virtual-phone.png';
import virtual_bg from '../img/virtual-tour-card-bg.png';
import baloon_icon from '../img/baloon-icon.png';
import google_store from '../img/google-store.png';
import apple_store from '../img/apple-store.png';
import { ArrowRightUp } from "../icons/ArrowRightUp.tsx";


function Home() {

  const dispatch = useDispatch();

  const { home_texts, isLoading, isError, message } = useSelector(
      (state) => state.texts
  )
  
  const { misc_texts } = useSelector(
      (state) => state.texts
  )

  useEffect(() => {
      if (isError) {
          toast.error(message);
      }
      if(localStorage.getItem("language") === null){
          localStorage.setItem("language", "en");
      }

      return () => {
          dispatch(reset());
      };
      
  }, [home_texts, misc_texts, isError, message, dispatch]);

  useEffect(() => {
      dispatch(getHomeTexts());
      dispatch(getMiscTexts());
  }, []);

  if(isLoading || !home_texts || !misc_texts){
    return <Spinner />
  }

  return (
    <div className="home-page">
      <Helmet>
        <title>Home - Tales of Reval</title>
        <meta name="description" content="Experience the most authentic medieval tours in Tallinn. Discover unique live experiences and historical adventures with Tales of Reval." />
        <meta name="keywords" content="Medieval Tours in Tallinn, Historical Experiences in Estonia, Interactive Medieval Experiences, Tallinn Guided Tours, Live Medieval Shows, Top Rated Tallinn Tours, Unique Tallinn Experiences, Authentic Tallinn Tours, Best Tallinn Attractions, Tallinn Tour Company" />
      </Helmet>
      <HomeLanding texts={home_texts}/>
      <div className="container">
        <OurServices texts={misc_texts}/>
        <OurTeam texts={misc_texts}/>
      </div>

      <div className="container">
        <div className="virtual-tour-card" style={{backgroundImage: `url(${virtual_bg})`}}>
          <div className="virtual-card-image">
            <img src={mobile_virtual_phone} alt="Virtual Tour" />
          </div>
          <div className="virtual-card-content">
            <h3 className="light cardo padding-10-bottom">Explore Alone, Discover More!</h3>
            <h5 className='blue-text'>Medieval adventure at your fingertips</h5>
            <div className="actions padding-20-top">
              <div className="read-more-action">
                <a style={{color:"#C1D0CD"}}href="/virtual" target='_blank'>
                  Read more
                </a>
                <ArrowRightUp color="#C1D0CD"/>
              </div>
              <div className="other-actions">
                <div className="action-image baloon">
                <a href="https://leplace.online" target="_blank">
                <img src={baloon_icon} alt="" />
                    </a>
                  
                </div>
                <div className="action-images flex gap-10">
                  <div className="action-image">
                    <a href="https://play.google.com/store/apps/details?id=com.leplace.global&pli=1" target="_blank">
                      <img src={google_store} alt="Google and Apple stores" />
                    </a>
                  </div>
                  <div className="action-image last">
                    <a  href="https://apps.apple.com/ee/app/leplace-world/id1496776027" target="_blank">
                      <img src={apple_store} alt="Google and Apple stores" />
                    </a>
                  </div>
                </div>
        
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Home
