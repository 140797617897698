import Rating from "react-rating"
import tripadvisorimg from "../img/trip-advisor.png"

import TripAdvisorButton from "./style-components/TripAdvisorButton"
import { FaRegCircle, FaCircle } from "react-icons/fa"

function Reviews({reviews, misc_texts}) {
  return (
    <div className="review-container padding-40-top">
      <div className="review-section">
        <div className="review">
          <h3 className='cardo padding-20-bottom'>{misc_texts?.["customers-love-us"]?.text || "Customers love us"}</h3>
          <p className='padding-20-bottom'>{misc_texts?.["review-text"]?.text || "This was absolutely amazing. The tour guide was so interesting, enthusiastic and humorous with an amazing knowledge of Tallin. The whole family thoroughly enjoyed it and we can't recommend it enough!"}</p>
          <p className="reviewer padding-20-bottom">{misc_texts?.["reviewer-name"]?.text || "Joh Doe"}</p>
          <div className="review-rating">
            <Rating
              emptySymbol={<FaRegCircle style={{margin: "0 2px"}} color="#05AA6C"/>}
              fullSymbol={<FaCircle color="#05AA6C"/>}
              initialRating={5}
              readonly
            />
            <p>at</p>
            <img src={tripadvisorimg} alt="" />
          </div>
        </div>
        
      </div>
      <div className="button-container">
        <TripAdvisorButton className="trip-advisor-button" text="Read more" link="https://www.tripadvisor.co.uk/Attraction_Review-g274958-d14768095-Reviews-Tales_of_Reval-Tallinn_Harju_County.html"/>
      </div>
    </div>
  )
}

export default Reviews
