import SocialButton from '../style-components/SocialButton';

function FooterColumnRight({ texts }) {
  const followUsText = texts && texts["follow-us:"] ? texts["follow-us:"].text : '';

  return (
    <div className="footer-column side right mobile-20-top">
      <h3 className="cardo padding-20-bottom">{followUsText}</h3>
      <div className="social-buttons">
        <SocialButton icon="Facebook" text="Facebook" link="https://www.facebook.com/TalesofReval/" />
        <SocialButton icon="Instagram" text="Instagram" link="https://www.instagram.com/talesofreval/" />
        <SocialButton icon="TripAdvisor" text="TripAdvisor" link="https://www.tripadvisor.co.uk/Attraction_Review-g274958-d14768095-Reviews-Tales_of_Reval-Tallinn_Harju_County.html" />
        <SocialButton icon="AirBnB" text="AirBnB" link="https://www.airbnb.co.uk/experiences/1096623" />
      </div>
    </div>
  );
}

export default FooterColumnRight;
